
:root {
  --maincolor: #5261DD;
  --seccolor: #2C3E50;
  --maincolor1: #00E898;
  --seccolor1: #BDC3C7;
  --maincolor2: #2ECC71;
  --secolor2:#E67E22;
  --maincolor3: rgba(11, 65, 130);
  --maincolor4: rgb(11, 130, 92);
  --seccolor3: rgba(82, 97,  221);
  --primary: rgba(0, 0, 0, 1);
  --secondary: rgba(0, 0, 0, 0.7);
  --tertiary: rgba(0, 0, 0, 0.5);
}


  .balance {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  .label {
    color: #555;
    margin-right: 5px;
  }
  
  .stats-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .stat {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .wins {
    color: #28a745;
  }
  
  .losses {
    color: #dc3545;
  }
  
  .stat-value {
    font-weight: bold;
  }
  


.price {
    font-size: 24px;
    font-family: 'Ubuntu', 'Arial', sans-serif;
    font-weight: 500;
    color: #0B4182;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .button-container {
    display: flex;
    gap: 20px;
  }

  .bet-container {
    display: flex;
    height: 60px;
    width: 100%;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .high-button {
    background-color: #28a745;
  }
  
  .low-button {
    background-color: #dc3545;
  }

.header {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .select {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }
  
  .input {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }


.package-header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8em;
    color: #333;
  }
  
  .package-grid {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .package-card {
    width: 220px;
    border-radius: 10px;
    /*box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);*/
    padding: 15px;
    text-align: center;
  }
  
  .package-card.basic {
    border-top: 5px solid #6c757d; /* Grey for basic */
  }
  
  .package-card.advanced {
    border-top: 5px solid #0d6efd; /* Blue for advanced */
  }
  
  .package-card.premium {
    border-top: 5px solid #28a745; /* Green for premium */
  }
  .badge-basic {
    display: flex;
    justify-content: center;
    height: 40px;
    padding-top: 9px;
  }
  
  .badge-advanced {
    display: flex;
    justify-content: center;
    height: 40px;
    padding-top: 9px;
  }
  
  .badge-premium {
    display: flex;
    justify-content: center;
    height: 40px;
    padding-top: 9px;
  }
  
  .card-headers {
    height: 40px;
  }
  .package-card h3 {
    font-size: 1.3em;
  }
  
  .package-card .btn {
    margin-top: 10px;
    width: 100%;
  }
  
  .package-card strong {
    color: #555;
  }


  .bets-info {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  
  .pending-bets-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .pending-bets-card h5 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .pending-bets-list {
    list-style-type: none;
    padding: 0;
    overflow-y: auto;
    max-height: 240px;
  }
  
  .pending-bets-list li {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    border-bottom: 1px solid #eee;
    font-size: 0.75rem;
  }
  
  .pending-bets-list li:last-child {
    border-bottom: none;
  }
  
  .bet-label {
    font-weight: 300;
  }
  
  .bet-price {
    color: #555;
    font-weight: 300;
  }
  
  .no-pending-bets {
    color: #777;
    font-size: 0.9rem;
    text-align: center;
  }
  
  .button.finalize-button {
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    transition: background-color 0.2s;
    margin-top: 0px !important;
  }
  
  .button.finalize-button:hover {
    background-color: #218838;
  }
  
  .pageTitle {
    font-size: 26px;
    font-weight: 400;
    color: #0B4182;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
    text-transform: none;
    font-family: 'Ubuntu', 'Arial', sans-serif;
  }

  .pagesubTitle {
    font-size: 26px;
    font-weight: 600;
    color: #0B4182;
    margin-top: 40px;
    text-align: center;
  }

  .description {
    font-family: 'Rubik', sans-serif; /* Specify the Rubik font */
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    text-align: center;
    margin-bottom: 0.1rem !important;
  }
  .bet {
    font-family: 'Rubik', sans-serif; /* Specify the Rubik font */
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: left;
    color: #0B4182;
  }
  .sub-header {
    padding-bottom: 10px;
  }

  .icon-size {
    width: 20px;
    height: auto;
  }

  .icon-high {
    width: 20px;
    height: auto;
    color: #218838;
    padding-left: 5px;
  }

  .icon-low {
    width: 20px;
    height: auto;
    color: #dc3545;
    padding-left: 5px;
  }
  .buton-secondary {
    background: rgba(82, 97,  221, 0.46);
    /*color: rgba(148, 77, 255, 1);*/
    color:#0B4182;
    border-radius: 5px;
    height: 36px;
    box-shadow: none;
    padding: 6px 6px;
    font-size: 14px;
    font-weight: 400;
  }
  .buton-sec-small {
    font-size: 11px;
  }
  
  .buton-secondary:hover {
    color: rgba(255, 255, 255);
    background: var(--seccolor3);
    box-shadow: none;
  }
  .buton {
    background: transparent;
    border: 0px solid transparent;
    border-radius: 5px;
    color: #0B4182;
    display: inline-block;
    width: 100%;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    background: rgba(82, 97,  221, 0.44);
    min-width: 40px;
    font-size: 15px;
    font-weight: 400;
    /*box-shadow: 0px 2px 10px rgba(148, 77, 255, 0.3);*/
  }
  .button-buy {
    width: 120px !important;
    display:flex;
    justify-content: center;
    align-items: center;


  }

  .chat-message {
    white-space: pre-wrap;
    margin-bottom: 10px;
    background: linear-gradient(0deg, rgba(82, 97,  221, 0.17) 0%, rgba(82, 97,  221, 0) 100%);
    padding: 10px;
    width: 340px;
  }

  .bet-card {
    white-space: pre-wrap;
    margin-bottom: 10px;
    background: linear-gradient(0deg, rgba(82, 97,  221, 0.17) 0%, rgba(82, 97,  221, 0) 100%);
    padding: 10px;
    width: 340px;
    height: 560px;
  }
  .card-100 {
    white-space: pre-wrap;
    margin-bottom: 10px;
    background: linear-gradient(0deg, rgba(82, 97,  221, 0.17) 0%, rgba(82, 97,  221, 0) 100%);
    padding: 10px;
    width: 60%;
    display: flex;
    position:relative;
    flex-direction: column;
  }
  .card-chat {
    padding-left: 30px;
  }

  .card-width {
    width: 350px !important;
  }
  .card-spacing {
    margin-bottom: 50px;
  }
  .globalLogo{
    width: 140px;
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    .card-fix {
      margin-bottom: 50px;
      width: 100%;
    }
    .globalLogo{
      width: 100px;
    }
  }
  @media screen and (max-width: 600px) {
    .card-title {
      font-size: 16px;
    }
  
    .card-balance-r {
      height: unset;
    }
  }
.card-title {
  display: flex;
  text-align: center;
  font-weight: 400 !important;
  font-size: 20px;
  color: #0B4182;
  margin-bottom: 20px;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  justify-content: center;
  }


  .gameLoader{
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0px;
    left: 0px;
  }

  .gameSpinner{
    display: block;
    margin: 0 auto;
    margin-top: 200px;
    text-align: center;
  }

  .text-game {
    font-weight: 200;
    text-align: justify;
    font-size: 13px;
  }
  .pkg-string {
    color: #5261DF;
    font-size: 14px;
    font-weight: 300;
  }
  .pkg-price {
    color: #5261DF;
    font-size: 14px;
    font-weight: 500;
  }
  .pkg {
    margin-right: 4px;
    color: #5261DF;
    font-size: 17px;
    padding-bottom: 2px; 
  }
  .btn-info {
    color: #ffffff;
    font-size: 27px;
    font-weight: 200;
    padding-right: 7px;
  }

  .package-s {
    color: #5261DF;
    font-size: 27px !important;
    font-weight: 400 !important;
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 0px;
    font-family: 'Ubuntu', 'Arial', sans-serif;
    text-transform: none !important;
  }
  .package-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: #ffffff; /* White text */
  /*border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);*/
  text-align: center;
  font-weight: bold;
}

.bg-basic {
  background-color: #6c757d; /* Secondary color */
}

.bg-advanced {
  background-color: #0d6efd; /* Primary color */
}

.bg-premium {
  background-color: #198754; /* Success color */
}
.bg-geton {
 /* background: linear-gradient(to bottom,  #5260df4f, #5260df09);/* Success color */
  background: transparent;
}


.btn-success-green{
  background-color: #28a745;
  color: #FFFFFF;
}
.btn-success-green:hover{
  background-color: #228a3a;
  color: #FFFFFF
}